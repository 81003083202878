<i18n src="@/common/locales.json"></i18n>
<template>
  <div id="nav">
    <b-navbar
      toggleable="lg"
      type="dark"
      v-bind:style="{
        'background-color': '#fcfcfc',
        height: 'auto',
        padding: '15px 20px',
      }"
    >
      <b-navbar-brand
        to="/"
        style="font-weight: 800; font-size: 1.5rem; color: #333;"
        >CANDiY</b-navbar-brand
      >
      <div class="col p-0 m-0">
        <AgoraNotif />
      </div>
      <!-- <b-button size="sm" to="/workspaces" variant="outline-warning"
        ><span v-if="currentWorkspace.name != undefined">{{
          currentWorkspace.name
        }}</span>
        <span v-else>Workspaces </span></b-button
      > -->

      <b-navbar-toggle target="nav-collapse"
        ><font-awesome-icon icon="fa-solid fa-bars" size="lg"
      /></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <!--  <b-nav-item  href="https://scenaristeur.github.io/spoggy-simple/?source=https://parle.solidcommunity.net/public/parle/" target="_blank">Viualization</b-nav-item>
        -->

          <!-- <b-nav-item-dropdown right>
        <template v-slot:button-content>
        {{ $t('databrowser') }}
      </template>
      <b-dropdown-item to="/storage">{{ $t('explorer') }} + {{ $t('editor') }}</b-dropdown-item>
      <b-dropdown-item to="/browser">{{ $t('explorer') }}</b-dropdown-item>
      <b-dropdown-item to="/editor">{{ $t('editor') }}</b-dropdown-item>
      <b-dropdown-item to="/bookmarks">{{ $t('bookmarks') }}</b-dropdown-item>
    </b-nav-item-dropdown> -->

          <b-nav-item to="/about">{{ $t("about") }}</b-nav-item>
          <b-nav-item to="/storage">{{ $t("storage") }}</b-nav-item>

          <b-nav-item-dropdown right>
            <template v-slot:button-content>
              {{ $t("User") }}
            </template>
            <b-dropdown-item
              to="/profile"
              style="border-bottom:1px solid #dcdcdc"
              >{{ $t("profile") }}</b-dropdown-item
            >
            <b-dropdown-item to="/friends">{{ $t("friends") }}</b-dropdown-item>
            <!-- <b-dropdown-item to="/indexes">Indexes</b-dropdown-item>
      <b-dropdown-item to="/general">Profile General</b-dropdown-item> -->
          </b-nav-item-dropdown>

          <b-nav-item-dropdown right>
            <template v-slot:button-content>
              {{ $t("communication") }}
            </template>
            <b-dropdown-item
              to="/inbox"
              style="border-bottom:1px solid #dcdcdc"
              >{{ $t("inbox") }}</b-dropdown-item
            >
            <b-dropdown-item to="/chat">{{ $t("chat") }}</b-dropdown-item>
            <!-- <b-dropdown-item to="/parle">{{ $t('talk') }}</b-dropdown-item> -->
          </b-nav-item-dropdown>
          <LangChooser />
          <!--    <b-nav-item href="#" disabled>Disabled</b-nav-item> -->
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <!-- <b-nav-form>
            <b-form-input
              size="sm"
              class="mr-sm-2"
              placeholder="Search"
            ></b-form-input>
            <b-button size="sm" class="my-2 my-sm-0" type="submit"
              >Search</b-button
            >
          </b-nav-form> -->

          <!-- <ShareButton /> -->

          <!-- Using 'button-content' slot -->
          <!-- <LangChooser /> -->

          <!-- <b-nav-item-dropdown right>
            <template v-slot:button-content>
              Menu
            </template>
            <b-dropdown-item to="/welcome">Welcome</b-dropdown-item>
            <b-dropdown-item to="/post">Post</b-dropdown-item>
            <b-dropdown-item to="/agora">Agora</b-dropdown-item>
            <b-dropdown-item to="/editor">{{ $t("editor") }}</b-dropdown-item>
            <b-dropdown-item to="/simplegouv">Simple Gouv</b-dropdown-item>
            <b-dropdown-item to="/semapps">SemApps</b-dropdown-item>
            <b-dropdown-item to="/workspaces">WorkSpaces</b-dropdown-item>
            <b-dropdown-item to="/offers">Offers & Ask</b-dropdown-item>
            <b-dropdown-item to="/migration"
              >Migration (solid.community ->
              solidcommunity.net)</b-dropdown-item
            >
            <b-dropdown-item to="/parle">{{ $t("talk") }}</b-dropdown-item>

            <b-dropdown-item to="/groups">Groups</b-dropdown-item>
            <b-dropdown-item to="/forms">Forms</b-dropdown-item>
            <b-dropdown-item to="/salut">Salut</b-dropdown-item>
            <b-dropdown-item to="/issuetrackers"
              >Issue Trackers</b-dropdown-item
            >
            <b-dropdown-item to="/p5">P5js</b-dropdown-item>

            <b-dropdown-item to="/pod">Pod 2 </b-dropdown-item>
            <b-dropdown-item to="/share">Share</b-dropdown-item>
            <b-dropdown-item to="/network">Network</b-dropdown-item>
            <b-dropdown-item to="/holon">Holon</b-dropdown-item>
            <b-dropdown-item to="/gouvernance1">Gouvernance 1</b-dropdown-item>
            <b-dropdown-item to="/gouvernance">Gouvernance</b-dropdown-item>
          </b-nav-item-dropdown> -->

          <b-nav-item><NaverLoginButton /></b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
//import SolidLogin from '@/components/solid/SolidLogin.vue'

export default {
  name: "NavBar",
  props: {
    msg: String,
  },
  components: {
    NaverLoginButton: () => import("@/components/naver/NaverLoginButton"),
    AgoraNotif: () => import("@/components/agora/AgoraNotif"),
    LangChooser: () => import("@/components/translation/LangChooser"),
    // ShareButton: () => import("@/components/share/ShareButton"),
  },
  methods: {},
  computed: {
    currentWorkspace: {
      get: function() {
        return this.$store.state.workspaces.currentWorkspace;
      },
      set: function() {},
    },
  },
  /*watch: {
  locale (val) {
  this.$i18n.locale = val
}
}*/
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*h3 {
margin: 40px 0 0;
}

li {
display: inline-block;
margin: 0 10px;
}
*/
ul {
  list-style-type: none;
  padding: 0;
  margin-top: 0;
  align-items: center;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 1) !important;
  font-size: 1.1rem !important;
  font-weight: 600 !important;
}
.navbar-dark .navbar-toggler {
  color: rgba(0, 0, 0, 1);
  border-color: rgba(0, 0, 0, 0);
  background-image: none;
  width: 56px;
  height: 40px;
}
#nav li > a {
  color: rgba(0, 0, 0, 1) !important;
  font-size: 1.1rem !important;
  font-weight: 600 !important;
}
</style>
